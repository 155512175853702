module.exports = [{
      plugin: require('/BlueBambooStudios/site/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser'),
      options: {"plugins":[],"pixelId":"613771271968599"},
    },{
      plugin: require('/BlueBambooStudios/site/node_modules/gatsby-plugin-canonical-urls/gatsby-browser'),
      options: {"plugins":[],"siteUrl":"http://bluebamboostudios.com"},
    },{
      plugin: require('/BlueBambooStudios/site/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/BlueBambooStudios/site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
